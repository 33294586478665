<template>
  <div class="index">
    <div class="xx index-1 Animation" :style="style">
      <div class="index-2 tran">
        <div class="bg-hex-2680D6 flex-none1"></div>
        <div class="bg-hex-FE373C flex-none1"></div>
        <div class="bg-white flex-none1"></div>
        <div class="bg-hex-2680D6 flex-none1"></div>
        <div class="bg-hex-FE373C flex-none1"></div>
        <div class="bg-white flex-none1"></div>
        <div class="bg-hex-2680D6 flex-none1"></div>
        <div class="bg-hex-FE373C flex-none1"></div>
        <div class="bg-white flex-none1"></div>
        <div class="bg-hex-2680D6 flex-none1"></div>
        <div class="bg-hex-FE373C flex-none1"></div>
        <div class="bg-white flex-none1"></div>
      </div>
      <div>
        <div :style="{ backgroundImage: 'url(' + image + ')' }" class="bg">
          <div class="top-465px">
            <div class="cs1">
              <div style="width: 300px">{{ blowing.classify_name }}</div>
              <div class="w-140px">
                {{ blowing.real_money ? parseFloat(blowing.real_money) : '' }}
              </div>
            </div>
            <div class="cs2">
              <div style="width: 230px">{{ purge.classify_name }}</div>
              <div class="cs3">
                {{ purge.real_money ? parseFloat(purge.real_money) : '' }}
              </div>
            </div>
          </div>
          <!--  -->
          <div class="cs4">
            <img class="cs5" :src="url" alt="" />
          </div>
          <!-- (countdown_time * 1000) - (Date.parse(new Date())) -->
          <div class="cs6" v-if="countdown_time">
            <van-count-down
              ref="countDown"
              @finish="finish"
              :time="countdown_time * 1000 - Date.parse(new Date())"
            >
              <template #default="timeData">
                <span style="font-size: 40px"
                  >{{ timeData.minutes }}分钟{{
                    timeData.seconds
                  }}秒后价格更新</span
                >
              </template>
            </van-count-down>
          </div>
          <!--  -->
          <div class="cs7">
            <div v-if="!is_work">下班中</div>
            <div v-if="minute == 0 && queue_num == 0 && is_work">
              无需排队，立即为您服务
            </div>
            <div v-if="minute == 0 && queue_num == 1 && is_work">
              前面有{{ queue_num }}人，还需稍作等待
            </div>
            <div v-if="minute != 0 && queue_num != 0 && is_work">
              前面有{{ queue_num }}人， 约需等待{{ minute }}分钟
            </div>
          </div>
          <!--  -->
          <div class="cs8">
            <div class="cs9">
              <div class="cs10">
                <div style="width: 290px">{{ name_1.classify_name }}</div>
                <div style="font-size: 30px; width: 80px">
                  {{ name_1.real_money ? parseFloat(name_1.real_money) : '' }}
                </div>
              </div>
              <div class="cs10" style="margin-left: -130px">
                <div style="width: 295px">{{ name_2.classify_name }}</div>
                <div style="font-size: 30px; width: 80px">
                  {{ name_2.real_money ? parseFloat(name_2.real_money) : '' }}
                </div>
              </div>
            </div>
            <div class="cs9">
              <div class="cs10">
                <div style="width: 290px">{{ name_3.classify_name }}</div>
                <div style="font-size: 30px; width: 80px">
                  {{ name_3.real_money ? parseFloat(name_3.real_money) : '' }}
                </div>
              </div>
              <div class="cs10" style="margin-left: -130px">
                <div style="width: 295px">{{ name_4.classify_name }}</div>
                <div style="font-size: 30px; width: 80px">
                  {{ name_4.real_money ? parseFloat(name_4.real_money) : '' }}
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="cs11">
            <div class="cs12">
              <div style="width: 300px">
                <van-progress
                  track-color="#f5f5f5"
                  stroke-width="45"
                  :show-pivot="false"
                  :percentage="
                    middle_evaluate_count + high_evaluate_count > 100
                      ? 100
                      : middle_evaluate_count + high_evaluate_count
                  "
                />
              </div>
              <div class="cs13">
                {{ middle_evaluate_count + high_evaluate_count }}%
              </div>
            </div>
            <div style="height: 50px"></div>
            <div class="cs12">
              <div style="width: 300px">
                <van-progress
                  track-color="#f5f5f5"
                  stroke-width="45"
                  :show-pivot="false"
                  :percentage="
                    high_evaluate_count > 100 ? 100 : high_evaluate_count
                  "
                />
              </div>
              <div class="cs13">{{ high_evaluate_count }}%</div>
            </div>
          </div>
          <!--  -->
          <div v-if="system.is_open_indoor == 'true'" class="cs14">
            <div class="cs12">
              <div>室外温度：</div>
              <div style="margin-left: 10px">
                {{ temperature }}
              </div>
            </div>
            <div class="cs15">
              <div>本店空调：</div>
              <div style="margin-left: 10px">
                {{ system.indoor_temperature }}℃
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const width = 1080
const height = 1920
export default {
  props: {
    sys: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      time: 0,
      image: require('@/assets/10800000.webp'),
      info: {},
      temperature: '',
      style: {
        width: width + 'px',
        height: height + 'px',
        transform: 'scale(1) translate(-50%,-50%)',
      },
    }
  },
  computed: {
    store_name() {
      return this.sys?.store_name ?? ''
    },
    store_address() {
      return this.sys?.store_address ?? ''
    },
    classify() {
      return this.info?.classify ?? []
    },
    //洗剪吹
    blowing() {
      return this.classify.length ? this.classify[0] : {}
    },
    //洗吹
    purge() {
      return this.classify.length ? this.classify[1] : {}
    },
    countdown_time() {
      return this.info?.countdown_time ?? 0
    },
    is_work() {
      return this.info?.is_work ?? false
    },
    minute() {
      return this.info?.minute ?? 0
    },
    queue_num() {
      return this.info?.queue_num ?? 0
    },
    //第一个
    name_1() {
      if (this.classify.length >= 6) {
        return this.classify[2]
      } else if (this.classify.length == 5) {
        return this.classify[0]
      } else if (this.classify.length == 4) {
        return this.classify[0]
      } else {
        return {}
      }
    },
    //第二个
    name_2() {
      if (this.classify.length >= 6) {
        return this.classify[3]
      } else if (this.classify.length == 5) {
        return this.classify[2]
      } else if (this.classify.length == 4) {
        return this.classify[1]
      } else {
        return {}
      }
    },
    //第三个
    name_3() {
      if (this.classify.length >= 6) {
        return this.classify[4]
      } else if (this.classify.length == 5) {
        return this.classify[3]
      } else if (this.classify.length == 4) {
        return this.classify[2]
      } else {
        return {}
      }
    },
    //第4个
    name_4() {
      if (this.classify.length >= 6) {
        return this.classify[5]
      } else if (this.classify.length == 5) {
        return this.classify[4]
      } else if (this.classify.length == 4) {
        return this.classify[3]
      } else {
        return {}
      }
    },
    middle_evaluate_count() {
      return this.info?.middle_evaluate_count ?? 0
    },
    high_evaluate_count() {
      return this.info?.high_evaluate_count ?? 0
    },
    system() {
      return this.info?.system ?? {}
    },
    url() {
      return this.info?.code_url ?? ''
    },
  },
  mounted() {
    document.title = this.store_name
    window.onresize = this.reSize
    this.reSize()
    this.getDate()
    // this.getwthrcdn()
    this.val = setInterval(() => {
      this.getDate()
      // this.getwthrcdn()
    }, 60 * 1000)
  },
  destroyed() {
    clearInterval(this.val)
  },
  methods: {
    getScale() {
      const w = window.innerWidth / width
      const h = window.innerHeight / height
      return w - h > 0 ? h : w
    },
    reSize() {
      this.style.transform = `scale(${this.getScale()}) translate(-50%,-50%)`
    },
    async getDate() {
      let { data } = await this.$api.post('Index/getH5Home')
      this.info = data
    },
    finish() {
      this.$refs.countDown.reset()
      this.getDate()
      this.getwthrcdn()
    },
    //获取当前温度
    async getwthrcdn() {
      let res = await this.$api.get(
        'http://wthrcdn.etouch.cn/weather_mini?city=重庆'
      )
      const weather = res.data.forecast[0].type ? res.data.forecast[0].type : ''
      const lower = res.data.forecast[0].low.substr(2)
      const igher = res.data.forecast[0].high.substr(2)
      this.temperature = weather + ' ' + lower + '~' + igher
    },
  },
}
</script>

<style scoped>
.xx {
  transform-origin: 0% 0%;
  position: absolute;
  left: 50%;
  top: 50%;
}
.bg {
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.tran {
  transform: rotate(-45deg);
  animation: Animation 16s linear infinite;
}

@keyframes Animation {
  0% {
    transform: translateX(600%) translateY(-40%) rotate(-45deg);
  }
  50% {
    transform: translateX(-600%) translateY(-40%) rotate(-45deg);
  }
  100% {
    transform: translateX(-600%) translateY(-40%) rotate(-45deg);
  }
}
.van-progress {
  border-radius: 100px;
}
.index {
  position: relative;
  height: 100vh;
  background: #000;
}
.index-1 {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
.index-2 {
  display: flex;
  height: 40000px;
}
.flex-none1 {
  flex: 0 0 auto;
  width: 500px;
  height: 100%;
}
.bg-hex-2680D6 {
  background: #2680d6;
}
.bg-hex-FE373C {
  background: #fe373c;
}
.bg-hex-white {
  background: #fff;
}
.top-465px {
  width: 100%;
  top: 465px;
  font-size: 60px;
  display: flex;
  text-align: center;
  align-items: center;
  position: absolute;
  padding: 0 42px;
}
.cs1 {
  display: flex;
  flex: 1;
  align-items: center;
}
.w-140px {
  width: 160px;
  /* flex: 1; */
  font-size: 50px;
  /* margin-left: 10px;
  margin-top: -30px; */
}
.cs2 {
  display: flex;
  flex: 1;
  align-items: center;
  text-align: center;
  margin-left: -60px;
}
.cs3 {
  /* width: 140px; */
  font-size: 50px;
  margin-left: 50px;
  /* margin-top: -40px; */
}
.cs4 {
  top: 640px;
  margin-left: 45px;
  position: absolute;
}
.cs5 {
  width: 330px;
  height: 330px;
  object-fit: cover;
}
.cs6 {
  padding: 0 120px;
  font-size: 60px;
  position: absolute;
  top: 1120px;
}
.cs7 {
  padding: 0 120px;
  font-size: 50px;
  position: absolute;
  top: 1200px;
}
.cs8 {
  padding: 0 60px;
  font-size: 50px;
  position: absolute;
  top: 1320px;
  width: 100%;
}
.cs9 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  height: 105px;
}
.cs10 {
  display: flex;
  align-items: center;
  flex: 1;
}
.cs11 {
  font-size: 40px;
  position: absolute;
  top: 1600px;
  width: 550px;
  right: 0;
  text-align: center;
  color: #fff;
}
.cs12 {
  display: flex;
  align-items: center;
}
.cs13 {
  text-align: center;
  width: 100px;
  margin-left: 10px;
}
.cs14 {
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 100px;
  position: absolute;
  bottom: 50px;
  right: 0;
  font-size: 30px;
}
.cs15 {
  display: flex;
  align-items: center;
  margin-left: 100px;
}
</style>
